import { useCallback } from 'react';

import {
  FetchLifestylesFilters,
  FetchLifestylesSort,
  FetchLifestylesLimit,
  FetchLifestylesGqlQuery,
  FetchLifestylesCacheKey,
  LifestyleNanoID
} from '../../lifestylesTypes';
import { FetchItemCacheKey, FetchItemsGqlQuery, UUID } from '../../../../types';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_LIFESTYLES_FILTERS,
  INITIAL_LIFESTYLES_LIMIT,
  INITIAL_LIFESTYLES_SORT
} from '../../lifestylesConstants';

interface LifestylesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface LifestylesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

interface LifestylesDefaultOptions {
  cacheKey: FetchLifestylesCacheKey;
  query: FetchLifestylesGqlQuery;
  initialFilters?: FetchLifestylesFilters;
  initialSort?: FetchLifestylesSort;
  initialLimit?: FetchLifestylesLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

type LifestylesOptions = LifestylesDefaultOptions &
  (LifestylesWithPrefetchItemOptions | LifestylesWithoutPrefetchItemOptions);

const scope = 'lifestyles';

function useLifestyles<
  LifestyleItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_LIFESTYLES_FILTERS,
  initialSort = INITIAL_LIFESTYLES_SORT,
  initialLimit = INITIAL_LIFESTYLES_LIMIT,
  options = {},
  fetchItemCacheKey,
  fetchItemQuery
}: LifestylesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    clearItemsFiltersPersistInitial,
    sortItems,
    prefetchItem,
    limitItems
  } = useInfiniteIndexQuery<LifestyleItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    fetchItemCacheKey,
    fetchItemQuery,
    scope
  });

  return {
    lifestylesData: data,
    lifestyles: items,
    lifestylesError: itemsError,
    lifestylesTotalCount: itemsTotalCount,
    lifestylesFetched: isFetched,
    lifestylesLoading: isLoading,
    lifestylesFetchingNextPage: isFetchingNextPage,
    lifestylesIsPlaceholderData: isPlaceholderData,
    lifestylesFilters: currentFilters,
    lifestylesSort: currentSort,
    lifestylesPage: currentPage,
    lifestylesLimit: currentLimit,
    hasNextLifestylesPage: hasNextPage,
    updateLifestyleCache: updateItemCache,
    loadMoreLifestyles: loadMoreItems,
    filterLifestyles: filterItems,
    changeLifestylesFilters: changeItemsFilters,
    clearLifestylesFilters: clearItemsFilters,
    clearLifestylesFiltersPersistInitial: clearItemsFiltersPersistInitial,
    sortLifestyles: sortItems,
    limitLifestyles: limitItems,
    prefetchLifestyle: useCallback(
      (lifestyleNanoId: LifestyleNanoID) => {
        const nanoId = lifestyleNanoId as unknown as UUID;
        prefetchItem(nanoId);
      },
      [prefetchItem]
    )
  };
}

export default useLifestyles;
